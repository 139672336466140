import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "../../../redux/configureStore"
import {
  fetchProfiles,
  selectAllProfiles,
  selectAllProfilesCount,
} from "../../../redux/adminProfiles"
import View from "./View"
import { useAuth0 } from "@auth0/auth0-react"

const AdminProfilesPage = () => {
  const auth = useAuth0()
  const dispatch = useAppDispatch()
  const profiles = useSelector(selectAllProfiles) || []
  const profilesCount = useSelector(selectAllProfilesCount) || 0

  const [search, setSearch] = useState("")
  const [page, setPage] = useState(1)

  useEffect(() => {
    ;(async () => {
      try {
        await dispatch(fetchProfiles({ auth, search, page }))
      } catch (error) {
        console.error(error)
      }
    })()
  }, [dispatch, auth, page, search])

  const handleChangeSearch = (value: string) => {
    setPage(1)
    setSearch(value)
  }

  return (
    <View
      profiles={profiles}
      profilesCount={profilesCount}
      onChangeSearch={handleChangeSearch}
      onChangePage={setPage}
      page={page}
    />
  )
}

export default AdminProfilesPage
