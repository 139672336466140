import React, { useEffect } from "react"
import { Switch, Route, Redirect, useLocation } from "react-router-dom"
import { useIntercom } from "react-use-intercom"
import NavbarUnauthenticated from "../components/NavbarUnauthenticated"
import * as RouteConstants from "../constants/routes"
// Disable routes temporarily for beta release
import HomePage from "../pages/HomePage"
import VerifiedEmail from "../pages/VerifiedEmail"
import NewUser from "../pages/NewUser"
import AboutPage from "../pages/AboutPage"
import PressPage from "../pages/PressPage"
import NominationPage from "../pages/Nominations"
import MemberBenefitsPage from "../pages/MemberBenefitsPage"
import TermsUsePage from "../pages/TermsUsePage"
import CookiePolicyPage from "../pages/CookiePolicyPage"
import ProfileDetailPage from "../pages/ProfileDetailPage"
import DisclaimerPage from "../pages/DisclaimerPage"
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage"
import GatedRegister from "../pages/GatedRegister"
import Footer from "../components/Footer"
import ProjectsPage from "../pages/ProjectsPage"
import ProjectDetailPage from "../pages/ProjectDetailPage"
import IndividualsGroupsPage from "../pages/IndividualsGroupsPage"
import ScrollToTop from "../components/ScrollToTop"

import SignUpPopUp from "../components/SignUpPopUp"
import { useWindowDimensions } from "../hooks"
import InnovationAwardPage from "../pages/InnovationAwardPage"
import CareerFairPage from "../pages/CareerFairPage"
import UnsubscribePage from "../pages/UnsubscribePage"
import WeekendCompetitionPage from "../pages/WeekendCompetitionPage"
import StudentAmbassadorPage from "../pages/StudentAmbassadorPage"
import JobsPage from "../pages/JobsPage"
import JobListingDetailsPage from "../pages/JobListingDetailsPage"
import StudioAwardPage from "../pages/StudioAwardPage"
import InternExpoPage from "../pages/InternExpoPage"
import LoginWithRedirectPage from "../pages/LoginWithRedirectPage"
import PricingPage from "../pages/PricingPage"
import NewsPage from "../pages/NewsPage"

export const UnauthenticatedRoutes = () => {
  const location = useLocation()
  const { width } = useWindowDimensions()
  const newUserPage = location.pathname.match(/new_user/)
  const { boot: bootIntercom } = useIntercom()

  useEffect(() => {
    try {
      bootIntercom()
    } catch (error) {
      console.error(error)
    }
  }, [bootIntercom])

  return (
    <>
      <ScrollToTop />
      {newUserPage ? "" : <NavbarUnauthenticated hideSignUp={true} />}
      <Switch>
        <Route exact path="/" component={HomePage} />{" "}
        <Route
          path={RouteConstants.PROFILE_CONTAINER + "/:username/projects/:id"}
          component={ProjectDetailPage}
        />
        <Route path="/jobs" component={JobsPage} />
        <Route
          path={
            RouteConstants.PROFILE_CONTAINER + "/:username/job_listings/:id"
          }
          component={JobListingDetailsPage}
        />
        <Route
          path={RouteConstants.PROFILE_CONTAINER + "/:username/:tab?"}
          component={ProfileDetailPage}
        />
        <Route
          path={"/innovation-awards/:slug"}
          component={InnovationAwardPage}
        />
        <Route
          path={"/weekend-competition/:slug"}
          component={WeekendCompetitionPage}
        />
        <Route path="/studio-awards/:slug" component={StudioAwardPage} />
        <Route path="/register/:type" component={GatedRegister} />
        <Route path="/register" component={GatedRegister} />
        <Route path="/unsub" component={UnsubscribePage} />
        <Route path="/new_user" component={NewUser} />
        <Route path="/verified_email" component={VerifiedEmail} />
        <Route path="/about" component={AboutPage} />
        <Route path="/press" component={PressPage} />
        <Route path="/member_benefits" component={MemberBenefitsPage} />
        <Route path="/terms_use" component={TermsUsePage} />
        <Route path="/cookie_policy" component={CookiePolicyPage} />
        <Route path="/disclaimer" component={DisclaimerPage} />
        <Route path="/privacy_policy" component={PrivacyPolicyPage} />
        <Route path="/featured_projects" component={NominationPage} />
        <Route path="/projects" component={ProjectsPage} />
        <Route path="/profiles" component={IndividualsGroupsPage} />
        <Route path="/student-ambassador" component={StudentAmbassadorPage} />
        <Route path="/career-fair" component={CareerFairPage} />
        <Route path="/firm-spotlight" component={CareerFairPage} />
        <Route path="/intern-expo" component={InternExpoPage} />
        <Route path="/login" component={LoginWithRedirectPage} />
        <Route path="/pricing" component={PricingPage} />
        <Route path="/news/:id" component={NewsPage} />
        <Route path="/*">
          <Redirect to="/" />
        </Route>
      </Switch>
      <SignUpPopUp />
      {location.pathname.startsWith("/register") && width < 960 ? null : (
        <Footer />
      )}
    </>
  )
}
