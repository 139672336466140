import React, { useCallback, useEffect } from "react"
import { useHistory, useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { useAppDispatch } from "../../redux/configureStore"
import { RootState } from "../../redux/rootReducer"
import { getJobListingById } from "../../redux/jobListings"

import Wizard from "./Wizard"

import LoadingPage from "../LoadingPage"
import { useAuth0 } from "@auth0/auth0-react"
import { Container } from "@material-ui/core"
import { getProducts, selectProducts } from "../../redux/product"
import { selectProfile } from "../../redux/profile"

const PromotionWizardPage = () => {
  const auth = useAuth0()
  const dispatch = useAppDispatch()
  const history = useHistory()

  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  const { id, operation } = useParams<{ id: string; operation: string }>()

  if (!["existing_job", "start"].includes(operation)) history.goBack()

  const selectedJob = useSelector((state: RootState) => {
    const { selectedJob } = state.jobListings
    return selectedJob && selectedJob.id === id ? selectedJob : undefined
  })

  const profile = useSelector(selectProfile)
  const products = useSelector(selectProducts)

  const fetchJobDetail = useCallback(async () => {
    await dispatch(getJobListingById({ auth, id }))
  }, [dispatch, auth, id])

  const fetchProducts = useCallback(async () => {
    await dispatch(getProducts({ type: "job", email: profile?.email }))
  }, [dispatch, profile])

  useEffect(() => {
    fetchJobDetail()
    fetchProducts()
  }, [fetchJobDetail, fetchProducts])

  return selectedJob && products ? (
    <>
      <Container>
        <Wizard job={selectedJob} products={products} operation={operation} />
      </Container>
    </>
  ) : (
    <LoadingPage />
  )
}

export default PromotionWizardPage
