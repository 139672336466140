import React, { useCallback, useEffect, useState } from "react"

import {
  CheckoutBox,
  FormContainer,
  SummaryBox,
  SummaryDivider,
  SummaryItem,
  SummarySpacing,
  SummaryTitle,
} from "./styles"

import StripeProvider from "../../components/StripeProvider"
import { getTax, Product, Tax, updateCheckout } from "../../redux/product"
import { useAppDispatch } from "../../redux/configureStore"
import { useAuth0 } from "@auth0/auth0-react"
import { JobListing } from "../../redux/jobListings"
import JobPromotionDialog from "../../components/JobPromotionDialog"
import { useSelector } from "react-redux"
import { selectProfile } from "../../redux/profile"

type Props = {
  handleBack: () => void
  product?: Product
  job: JobListing
}
const Payment: React.FC<Props> = ({ handleBack, product, job }: Props) => {
  const auth = useAuth0()
  const dispatch = useAppDispatch()

  const profile = useSelector(selectProfile)

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [tax, setTax] = useState<Tax>()

  const mutateCheckout = useCallback(async () => {
    if (profile?.email) {
      await dispatch(updateCheckout({ email: profile.email }))
    }
  }, [dispatch, profile])

  useEffect(() => {
    mutateCheckout()
  }, [mutateCheckout])

  const calculateTax = async (
    line1: string,
    city: string,
    state: string,
    country: string,
    postalCode: string
  ) => {
    if (product && line1 && city && state && country && postalCode) {
      const { type, payload } = await dispatch(
        getTax({
          auth,
          productId: product.id,
          address: { line1, city, state, country, postalCode },
        })
      )
      if (type === getTax.fulfilled.type) {
        setTax(payload)
      }
    }
  }

  const total = product ? product?.amount / 100 + (tax?.taxRate ?? 0) : 0

  return (
    <CheckoutBox>
      <FormContainer>
        <StripeProvider
          job={job}
          tax={tax}
          handleBack={handleBack}
          calculateTax={calculateTax}
          product={product}
          handleOpenDialog={() => setIsDialogOpen(true)}
        />
      </FormContainer>
      <SummaryBox>
        <SummaryTitle>Order Summary</SummaryTitle>
        <SummaryDivider />
        <SummaryItem style={{ color: "#525252" }}>
          <span>{product?.title}</span>
          <span>
            {((product?.amount || 0) / 100).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
            })}
            /mo
          </span>
        </SummaryItem>
        <SummaryDivider />
        <SummaryItem>
          <span>Subtotal</span>
          <span>
            {((product?.amount || 0) / 100).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
            })}
            /mo
          </span>
        </SummaryItem>
        <SummarySpacing />
        <SummaryItem>
          <span>Estimated Tax</span>
          <span>
            {tax?.taxRate ? (
              <>
                {tax.taxRate.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 2,
                })}
              </>
            ) : (
              <>...</>
            )}
          </span>
        </SummaryItem>
        <SummarySpacing />
        <SummaryItem style={{ fontFamily: "Public-Sans-Bold" }}>
          <span>Estimated Total</span>
          <span>
            {total.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
            })}
            /mo
          </span>
        </SummaryItem>
        {/* <SummaryDivider />
        <SummaryItem>
          <TextField variant="outlined" placeholder="Add promotion code"/>
          <Button children={"Apply"} color="secondary"/>
        </SummaryItem> */}
      </SummaryBox>
      <JobPromotionDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        job={job}
      />
    </CheckoutBox>
  )
}

export default Payment
