import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useApi } from "../hooks/useApi"
import { CommentMention, Comment, Project } from "./projects"
import { Post, PostComment, PostMention } from "./posts"
import { Profile } from "./profile"
import { Auth0ContextInterface } from "@auth0/auth0-react"
import { JobListing } from "./jobListings"
import { News } from "./adminNews"

//#region types
export type Feed = {
  id?: string
  type: string
  source: string
  project?: Project
  post?: Post
  connections?: Profile[]
  jobs?: JobListing[]
  news?: News
}

export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

type SliceState = {
  feed: Feed[]
  searchFeed: Feed[]
  hash: string
  searchHash: string
  feedCount: number
  status: LoadingStatuses
  error: string | null | undefined
}
//#endregion

//#region api
type GetFeedPayload = {
  email?: string
  idHash?: string
  pagination?: boolean
}
export const getFeed = createAsyncThunk<any, GetFeedPayload>(
  "feed/get",
  async ({ email, idHash, pagination }) => {
    let endpoint = `/feed`
    if (email) endpoint += `?email=${email}`

    const body = { hash: pagination ? idHash : "" }

    return useApi(null, endpoint, {
      method: "POST",
      body: JSON.stringify(body),
    }).then((res) => res.json())
  }
)

type GetSearchFeedPayload = {
  auth: Auth0ContextInterface
  idHash?: string
  pagination?: boolean
  search?: string
  order?: string
  isHashtag: boolean
}
export const filterFeed = createAsyncThunk<any, GetSearchFeedPayload>(
  "feed/get/filter",
  async ({
    auth,
    idHash,
    pagination,
    search,
    isHashtag,
    order = "date DESC",
  }) => {
    let endpoint = `/feed/search`
    if (search) endpoint += `?search=${search}`
    if (isHashtag) endpoint += `&type=hashtag`
    if (order) endpoint += `&order=${order}`

    const body = { hash: pagination ? idHash : "" }

    return useApi(auth, endpoint, {
      method: "POST",
      body: JSON.stringify(body),
    }).then((res) => res.json())
  }
)

type UpdateFeedStatePayload = {
  type: "post" | "project" | "news"
  id: string
  action: "comment" | "nominate" | "like" | "share" | "dislike" | "unnominate"
  comment?: Comment | PostComment
  mentions?: CommentMention[] | PostMention[]
  parentCommentId?: string
  lastNominated?: string
  lastNominationDate?: Date
}

export const updateFeedState = createAsyncThunk<any, UpdateFeedStatePayload>(
  "feed/updateFeedState",
  async ({ type, id, action, comment, lastNominated, lastNominationDate }) => {
    return ""
  }
)

type DeleteCommentStatePayload = {
  type: "post" | "project" | "news"
  commentId: string
  profileId: string
  postId?: string
  projectId?: string
  newsId?: string
  parentCommentId?: string
}

export const deleteCommentState = createAsyncThunk<
  any,
  DeleteCommentStatePayload
>(
  "feed/deleteCommentState",
  async ({
    type,
    commentId,
    postId,
    projectId,
    newsId,
    parentCommentId,
    profileId,
  }) => {
    return ""
  }
)

type UpdateCommentStatePayload = {
  type: "post" | "project" | "news"
  comment: Comment | PostComment
  postId?: string
  projectId?: string
  newsId?: string
}

export const updateCommentState = createAsyncThunk<
  any,
  UpdateCommentStatePayload
>("feed/updateCommentState", async ({ type, postId, projectId, comment }) => {
  return ""
})
//#endregion

//#region slice
const initialState: SliceState = {
  feed: [],
  searchFeed: [],
  hash: "",
  searchHash: "",
  feedCount: 0,
  status: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: "feed",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFeed.pending, (state, action) => {
      if (!action.meta.arg.pagination) {
        state.feed = []
        state.hash = ""
        state.status = LoadingStatuses.Loading
        state.feedCount = 0
      }
    })
    builder.addCase(getFeed.fulfilled, (state, action) => {
      state.feed =
        state.feed.length === 0
          ? action.payload.feed
          : state.feed?.concat(action.payload.feed)
      state.hash = action.payload.hash
      state.status = LoadingStatuses.Succeeded
      state.feedCount = state.feedCount + action.payload.feed.length
    })

    builder.addCase(filterFeed.pending, (state, action) => {
      if (!action.meta.arg.pagination) {
        state.searchFeed = []
        state.searchHash = ""
        state.status = LoadingStatuses.Loading
      }
    })
    builder.addCase(filterFeed.fulfilled, (state, action) => {
      state.searchFeed =
        state.searchFeed.length === 0
          ? action.payload.feed
          : state.searchFeed?.concat(action.payload.feed)
      state.searchHash = action.payload.hash
      state.status = LoadingStatuses.Succeeded
    })
    builder.addCase(deleteCommentState.fulfilled, (state, action) => {
      const type = action.meta.arg.type
      const commentId = action.meta.arg.commentId
      const parentCommentId = action.meta.arg.parentCommentId
      const profileId = action.meta.arg.profileId

      if (type === "project") {
        const projectId = action.meta.arg.projectId

        if (state.feed) {
          const updatedFeed = [...state.feed]
          updatedFeed.forEach((feed) => {
            if (
              feed.project &&
              feed.project.id! === projectId &&
              feed.project.comments
            ) {
              if (parentCommentId) {
                const comments = [...feed.project.comments]
                const updatedComments = comments.map((comment) => {
                  if (comment.id === parentCommentId) {
                    comment.replies = comment.replies?.filter(
                      (reply) => reply.id !== commentId
                    )
                  }
                  return comment
                })
                feed.project.commentsCount!--
                feed.project.commented = updatedComments.some(
                  (comment) => comment.authorId === profileId
                )
                feed.project.comments = updatedComments
              } else {
                const comments = [...feed.project.comments]
                const updatedComments = comments.filter(
                  (comment) => comment.id !== commentId
                )
                feed.project.commentsCount!--
                feed.project.commented = updatedComments.some(
                  (comment) => comment.authorId === profileId
                )
                feed.project.comments = updatedComments
              }
            }
          })
          state.feed = updatedFeed
        }

        if (state.searchFeed) {
          const updatedSearchFeed = [...state.searchFeed]
          updatedSearchFeed.forEach((feed) => {
            if (
              feed.project &&
              feed.project.id! === projectId &&
              feed.project.comments
            ) {
              if (parentCommentId) {
                const comments = [...feed.project.comments]
                const updatedComments = comments.map((comment) => {
                  if (comment.id === parentCommentId) {
                    comment.replies = comment.replies?.filter(
                      (reply) => reply.id !== commentId
                    )
                  }
                  return comment
                })
                feed.project.commentsCount!--
                feed.project.commented = comments.some(
                  (comment) => comment.authorId === profileId
                )
                feed.project.comments = updatedComments
              } else {
                const comments = [...feed.project.comments]
                const updatedComments = comments.filter(
                  (comment) => comment.id !== commentId
                )
                feed.project.commentsCount!--
                feed.project.commented = updatedComments.some(
                  (comment) => comment.authorId === profileId
                )
                feed.project.comments = updatedComments
              }
            }
          })
          state.searchFeed = updatedSearchFeed
        }
      } else if (type === "post") {
        const postId = action.meta.arg.postId

        if (state.feed) {
          const updatedFeed = [...state.feed]
          updatedFeed.forEach((feed) => {
            if (feed.post && feed.post.id! === postId && feed.post.comments) {
              if (parentCommentId) {
                const comments = [...feed.post.comments]
                const updatedComments = comments.map((comment) => {
                  if (comment.id === parentCommentId) {
                    comment.replies = comment.replies?.filter(
                      (reply) => reply.id !== commentId
                    )
                  }
                  return comment
                })
                feed.post.commentCount!--
                feed.post.commented = updatedComments.some(
                  (comment) => comment.profileId === profileId
                )
                feed.post.comments = updatedComments
              } else {
                const comments = [...feed.post.comments]
                const updatedComments = comments.filter(
                  (comment) => comment.id !== commentId
                )
                feed.post.commentCount!--
                feed.post.commented = updatedComments.some(
                  (comment) => comment.profileId === profileId
                )
                feed.post.comments = updatedComments
              }
            }
          })
          state.feed = updatedFeed
        }

        if (state.searchFeed) {
          const updatedSearchFeed = [...state.searchFeed]
          updatedSearchFeed.forEach((feed) => {
            if (feed.post && feed.post.id! === postId && feed.post.comments) {
              if (parentCommentId) {
                const comments = [...feed.post.comments]
                const updatedComments = comments.map((comment) => {
                  if (comment.id === parentCommentId) {
                    comment.replies = comment.replies?.filter(
                      (reply) => reply.id !== commentId
                    )
                  }
                  return comment
                })
                feed.post.commentCount!--
                feed.post.commented = updatedComments.some(
                  (comment) => comment.profileId === profileId
                )
                feed.post.comments = updatedComments
              } else {
                const comments = [...feed.post.comments]
                const updatedComments = comments.filter(
                  (comment) => comment.id !== commentId
                )
                feed.post.commentCount!--
                feed.post.commented = updatedComments.some(
                  (comment) => comment.profileId === profileId
                )
                feed.post.comments = updatedComments
              }
            }
          })
          state.searchFeed = updatedSearchFeed
        }
      } else if (type === "news") {
        const newsId = action.meta.arg.newsId

        if (state.feed) {
          const updatedFeed = [...state.feed]
          updatedFeed.forEach((feed) => {
            if (feed.news && feed.news.id! === newsId && feed.news.comments) {
              if (parentCommentId) {
                const comments = [...feed.news.comments]
                const updatedComments = comments.map((comment) => {
                  if (comment.id === parentCommentId) {
                    comment.replies = comment.replies?.filter(
                      (reply) => reply.id !== commentId
                    )
                  }
                  return comment
                })
                feed.news.commentCount!--
                feed.news.commented = updatedComments.some(
                  (comment) => comment.authorId === profileId
                )
                feed.news.comments = updatedComments
              } else {
                const comments = [...feed.news.comments]
                const updatedComments = comments.filter(
                  (comment) => comment.id !== commentId
                )
                feed.news.commentCount!--
                feed.news.commented = updatedComments.some(
                  (comment) => comment.authorId === profileId
                )
                feed.news.comments = updatedComments
              }
            }
          })
          state.feed = updatedFeed
        }

        if (state.searchFeed) {
          const updatedSearchFeed = [...state.searchFeed]
          updatedSearchFeed.forEach((feed) => {
            if (feed.news && feed.news.id! === newsId && feed.news.comments) {
              if (parentCommentId) {
                const comments = [...feed.news.comments]
                const updatedComments = comments.map((comment) => {
                  if (comment.id === parentCommentId) {
                    comment.replies = comment.replies?.filter(
                      (reply) => reply.id !== commentId
                    )
                  }
                  return comment
                })
                feed.news.commentCount!--
                feed.news.commented = comments.some(
                  (comment) => comment.authorId === profileId
                )
                feed.news.comments = updatedComments
              } else {
                const comments = [...feed.news.comments]
                const updatedComments = comments.filter(
                  (comment) => comment.id !== commentId
                )
                feed.news.commentCount!--
                feed.news.commented = updatedComments.some(
                  (comment) => comment.authorId === profileId
                )
                feed.news.comments = updatedComments
              }
            }
          })
          state.searchFeed = updatedSearchFeed
        }
      }
    })
    builder.addCase(updateCommentState.fulfilled, (state, action) => {
      const type = action.meta.arg.type
      const editedComment = action.meta.arg.comment

      if (type === "project") {
        if (state.feed) {
          const updatedFeed = [...state.feed]
          updatedFeed.forEach((feed) => {
            if (
              feed.project &&
              feed.project.comments &&
              feed.project.id! === action.meta.arg.projectId
            ) {
              if (editedComment.parentCommentId) {
                const comments = [...feed.project.comments]
                const updatedComments = comments.map((comment) => {
                  if (comment.id === editedComment.parentCommentId) {
                    comment.replies = comment.replies?.map((reply) => {
                      if (reply.id === editedComment.id) return editedComment
                      return reply
                    })
                  }
                  return comment
                })
                feed.project.comments = updatedComments
              } else {
                const comments = [...feed.project.comments]
                const updatedComments = comments.map((comment) => {
                  if (comment.id === editedComment.id) return editedComment
                  return comment
                })
                feed.project.comments = updatedComments
              }
            }
          })
          state.feed = updatedFeed
        }

        if (state.searchFeed) {
          const updatedSearchFeed = [...state.searchFeed]
          updatedSearchFeed.forEach((feed) => {
            if (
              feed.project &&
              feed.project.comments &&
              feed.project.id! === action.meta.arg.projectId
            ) {
              if (editedComment.parentCommentId) {
                const comments = [...feed.project.comments]
                const updatedComments = comments.map((comment) => {
                  if (comment.id === editedComment.parentCommentId) {
                    comment.replies = comment.replies?.map((reply) => {
                      if (reply.id === editedComment.id) return editedComment
                      return reply
                    })
                  }
                  return comment
                })
                feed.project.comments = updatedComments
              } else {
                const comments = [...feed.project.comments]
                const updatedComments = comments.map((comment) => {
                  if (comment.id === editedComment.id) return editedComment
                  return comment
                })
                feed.project.comments = updatedComments
              }
            }
          })
          state.searchFeed = updatedSearchFeed
        }
      } else if (type === "post") {
        if (state.feed) {
          const updatedFeed = [...state.feed]
          updatedFeed.forEach((feed) => {
            if (
              feed.post &&
              feed.post.comments &&
              feed.post.id! === action.meta.arg.postId
            ) {
              if (editedComment.parentCommentId) {
                const comments = [...feed.post.comments]
                const updatedComments = comments.map((comment) => {
                  if (comment.id === editedComment.parentCommentId) {
                    comment.replies = comment.replies?.map((reply) => {
                      if (reply.id === editedComment.id) return editedComment
                      return reply
                    })
                  }
                  return comment
                })
                feed.post.comments = updatedComments
              } else {
                const comments = [...feed.post.comments]
                const updatedComments = comments.map((comment) => {
                  if (comment.id === editedComment.id) return editedComment
                  return comment
                })
                feed.post.comments = updatedComments
              }
            }
          })
          state.feed = updatedFeed
        }

        if (state.searchFeed) {
          const updatedSearchFeed = [...state.searchFeed]
          updatedSearchFeed.forEach((feed) => {
            if (
              feed.post &&
              feed.post.comments &&
              feed.post.id! === action.meta.arg.postId
            ) {
              if (editedComment.parentCommentId) {
                const comments = [...feed.post.comments]
                const updatedComments = comments.map((comment) => {
                  if (comment.id === editedComment.parentCommentId) {
                    comment.replies = comment.replies?.map((reply) => {
                      if (reply.id === editedComment.id) return editedComment
                      return reply
                    })
                  }
                  return comment
                })
                feed.post.comments = updatedComments
              } else {
                const comments = [...feed.post.comments]
                const updatedComments = comments.map((comment) => {
                  if (comment.id === editedComment.id) return editedComment
                  return comment
                })
                feed.post.comments = updatedComments
              }
            }
          })
          state.searchFeed = updatedSearchFeed
        }
      } else if (type === "news") {
        if (state.feed) {
          const updatedFeed = [...state.feed]
          updatedFeed.forEach((feed) => {
            if (
              feed.news &&
              feed.news.comments &&
              feed.news.id! === action.meta.arg.newsId
            ) {
              if (editedComment.parentCommentId) {
                const comments = [...feed.news.comments]
                const updatedComments = comments.map((comment) => {
                  if (comment.id === editedComment.parentCommentId) {
                    comment.replies = comment.replies?.map((reply) => {
                      if (reply.id === editedComment.id) return editedComment
                      return reply
                    })
                  }
                  return comment
                })
                feed.news.comments = updatedComments
              } else {
                const comments = [...feed.news.comments]
                const updatedComments = comments.map((comment) => {
                  if (comment.id === editedComment.id) return editedComment
                  return comment
                })
                feed.news.comments = updatedComments
              }
            }
          })
          state.feed = updatedFeed
        }

        if (state.searchFeed) {
          const updatedSearchFeed = [...state.searchFeed]
          updatedSearchFeed.forEach((feed) => {
            if (
              feed.news &&
              feed.news.comments &&
              feed.news.id! === action.meta.arg.newsId
            ) {
              if (editedComment.parentCommentId) {
                const comments = [...feed.news.comments]
                const updatedComments = comments.map((comment) => {
                  if (comment.id === editedComment.parentCommentId) {
                    comment.replies = comment.replies?.map((reply) => {
                      if (reply.id === editedComment.id) return editedComment
                      return reply
                    })
                  }
                  return comment
                })
                feed.news.comments = updatedComments
              } else {
                const comments = [...feed.news.comments]
                const updatedComments = comments.map((comment) => {
                  if (comment.id === editedComment.id) return editedComment
                  return comment
                })
                feed.news.comments = updatedComments
              }
            }
          })
          state.searchFeed = updatedSearchFeed
        }
      }
    })
    builder.addCase(updateFeedState.fulfilled, (state, action) => {
      const type = action.meta.arg.type
      const actionType = action.meta.arg.action
      const id = action.meta.arg.id

      if (type === "project") {
        if (actionType === "nominate") {
          const lastNominationDate = new Date(
            action.meta.arg.lastNominationDate as any
          )
          const now =
            new Date().getDate() +
            new Date().getMonth() +
            new Date().getFullYear()
          const lastNomination = action.meta.arg.lastNominationDate
            ? lastNominationDate.getDate() +
              lastNominationDate.getMonth() +
              lastNominationDate.getFullYear()
            : 0

          const updatedFeed = [...state.feed]
          updatedFeed.forEach((feed) => {
            if (feed.project) {
              if (
                now === lastNomination &&
                feed.project.id! === action.meta.arg.lastNominated
              ) {
                feed.project.nominations!--
                feed.project.nominated = false
              }

              if (feed.project.id! === id) {
                feed.project.nominations!++
                feed.project.nominated = true
              }
            }
          })
          state.feed = updatedFeed

          const updatedFilteredFeed = [...state.searchFeed]
          updatedFilteredFeed.forEach((feed) => {
            if (feed.project) {
              if (
                now === lastNomination &&
                feed.project.id! === action.meta.arg.lastNominated
              ) {
                feed.project.nominations!--
                feed.project.nominated = false
              }

              if (feed.project.id! === id) {
                feed.project.nominations!++
                feed.project.nominated = true
              }
            }
          })
          state.searchFeed = updatedFilteredFeed
        } else if (actionType === "unnominate") {
          const updatedFeed = [...state.feed]
          updatedFeed.forEach((feed) => {
            if (feed.project) {
              if (feed.project.id! === id) {
                feed.project.nominations!--
                feed.project.nominated = false
              }
            }
          })
          state.feed = updatedFeed

          const updatedFilteredFeed = [...state.searchFeed]
          updatedFilteredFeed.forEach((feed) => {
            if (feed.project) {
              if (feed.project.id! === id) {
                feed.project.nominations!--
                feed.project.nominated = false
              }
            }
          })
          state.searchFeed = updatedFilteredFeed
        } else if (actionType === "like") {
          const updatedFeed = [...state.feed]
          updatedFeed.forEach((feed) => {
            if (feed.project) {
              if (feed.project.id! === id) {
                feed.project.likes!++
                feed.project.liked = true
              }
            }
          })
          state.feed = updatedFeed

          const updatedFilteredFeed = [...state.searchFeed]
          updatedFilteredFeed.forEach((feed) => {
            if (feed.project) {
              if (feed.project.id! === id) {
                feed.project.likes!++
                feed.project.liked = true
              }
            }
          })
          state.searchFeed = updatedFilteredFeed
        } else if (actionType === "dislike") {
          const updatedFeed = [...state.feed]
          updatedFeed.forEach((feed) => {
            if (feed.project) {
              if (feed.project.id! === id) {
                feed.project.likes!--
                feed.project.liked = false
              }
            }
          })
          state.feed = updatedFeed

          const updatedFilteredFeed = [...state.searchFeed]
          updatedFilteredFeed.forEach((feed) => {
            if (feed.project) {
              if (feed.project.id! === id) {
                feed.project.likes!--
                feed.project.liked = false
              }
            }
          })
          state.searchFeed = updatedFilteredFeed
        } else if (actionType === "share") {
          const updatedFeed = [...state.feed]
          updatedFeed.forEach((feed) => {
            if (feed.project) {
              if (feed.project.id! === id) {
                feed.project.shares!++
                feed.project.shared = true
              }
            }
          })
          state.feed = updatedFeed

          const updatedFilteredFeed = [...state.searchFeed]
          updatedFilteredFeed.forEach((feed) => {
            if (feed.project) {
              if (feed.project.id! === id) {
                feed.project.shares!++
                feed.project.shared = true
              }
            }
          })
          state.searchFeed = updatedFilteredFeed
        } else if (actionType === "comment") {
          const parentCommentId = action.meta.arg.parentCommentId

          const updatedFeed = [...state.feed]
          updatedFeed.forEach((feed) => {
            if (
              feed.project &&
              feed.project.id! === id &&
              feed.project.comments
            ) {
              if (parentCommentId) {
                const updatedComments = [...feed.project.comments]
                updatedComments.forEach((comment) => {
                  if (comment.id === parentCommentId) {
                    const newReply = action.meta.arg.comment as Comment
                    if (
                      action.meta.arg.mentions &&
                      action.meta.arg.mentions.length > 0
                    ) {
                      newReply.mentions = action.meta.arg.mentions
                    }
                    comment.replies!.unshift(newReply)
                  }
                })
                feed.project.comments = updatedComments
              } else {
                const newComment = action.meta.arg.comment as Comment
                if (
                  action.meta.arg.mentions &&
                  action.meta.arg.mentions.length > 0
                ) {
                  newComment.mentions = action.meta.arg.mentions
                }
                feed.project.comments!.unshift(newComment)
              }
              feed.project.commentsCount!++
              feed.project.commented = true
            }
          })
          state.feed = updatedFeed

          const updatedFilteredFeed = [...state.searchFeed]
          updatedFilteredFeed.forEach((feed) => {
            if (
              feed.project &&
              feed.project.id! === id &&
              feed.project.comments
            ) {
              if (parentCommentId) {
                const updatedComments = [...feed.project.comments]
                updatedComments.forEach((comment) => {
                  if (comment.id === parentCommentId) {
                    const newReply = action.meta.arg.comment as Comment
                    if (
                      action.meta.arg.mentions &&
                      action.meta.arg.mentions.length > 0
                    ) {
                      newReply.mentions = action.meta.arg.mentions
                    }
                    comment.replies!.unshift(newReply)
                  }
                })
                feed.project.comments = updatedComments
              } else {
                const newComment = action.meta.arg.comment as Comment
                if (
                  action.meta.arg.mentions &&
                  action.meta.arg.mentions.length > 0
                ) {
                  newComment.mentions = action.meta.arg.mentions
                }
                feed.project.comments!.unshift(newComment)
              }
              feed.project.commentsCount!++
              feed.project.commented = true
            }
          })
          state.searchFeed = updatedFilteredFeed
        }
      } else if (type === "post") {
        if (actionType === "comment") {
          const parentCommentId = action.meta.arg.parentCommentId

          const updatedFeed = [...state.feed]
          updatedFeed.forEach((feed) => {
            if (feed.post && feed.post.id! === id && feed.post.comments) {
              if (parentCommentId) {
                const updatedComments = [...feed.post.comments]
                updatedComments.forEach((comment) => {
                  if (comment.id === parentCommentId) {
                    const newReply = action.meta.arg.comment as PostComment
                    if (
                      action.meta.arg.mentions &&
                      action.meta.arg.mentions.length > 0
                    ) {
                      newReply.mentions = action.meta.arg.mentions
                    }
                    comment.replies!.unshift(newReply)
                  }
                })
                feed.post.comments = updatedComments
              } else {
                const newComment = action.meta.arg.comment as PostComment
                if (
                  action.meta.arg.mentions &&
                  action.meta.arg.mentions.length > 0
                ) {
                  newComment.mentions = action.meta.arg.mentions
                }
                feed.post.comments!.unshift(newComment)
              }
              feed.post.commentCount!++
              feed.post.commented = true
            }
          })
          state.feed = updatedFeed

          const updatedFilteredFeed = [...state.searchFeed]
          updatedFilteredFeed.forEach((feed) => {
            if (feed.post && feed.post.id! === id && feed.post.comments) {
              if (parentCommentId) {
                const updatedComments = [...feed.post.comments]
                updatedComments.forEach((comment) => {
                  if (comment.id === parentCommentId) {
                    const newReply = action.meta.arg.comment as PostComment
                    if (
                      action.meta.arg.mentions &&
                      action.meta.arg.mentions.length > 0
                    ) {
                      newReply.mentions = action.meta.arg.mentions
                    }
                    comment.replies!.unshift(newReply)
                  }
                })
                feed.post.comments = updatedComments
              } else {
                const newComment = action.meta.arg.comment as PostComment
                if (
                  action.meta.arg.mentions &&
                  action.meta.arg.mentions.length > 0
                ) {
                  newComment.mentions = action.meta.arg.mentions
                }
                feed.post.comments!.unshift(newComment)
              }
              feed.post.commentCount!++
              feed.post.commented = true
            }
          })
          state.searchFeed = updatedFilteredFeed
        } else if (actionType === "like") {
          const updatedFeed = [...state.feed]
          updatedFeed.forEach((feed) => {
            if (feed.post) {
              if (feed.post.id! === id) {
                feed.post.likeCount!++
                feed.post.liked = true
              }
            }
          })
          state.feed = updatedFeed

          const updatedFilteredFeed = [...state.searchFeed]
          updatedFilteredFeed.forEach((feed) => {
            if (feed.post) {
              if (feed.post.id! === id) {
                feed.post.likeCount!++
                feed.post.liked = true
              }
            }
          })
          state.searchFeed = updatedFilteredFeed
        } else if (actionType === "dislike") {
          const updatedFeed = [...state.feed]
          updatedFeed.forEach((feed) => {
            if (feed.post) {
              if (feed.post.id! === id) {
                feed.post.likeCount!--
                feed.post.liked = false
              }
            }
          })
          state.feed = updatedFeed

          const updatedFilteredFeed = [...state.searchFeed]
          updatedFilteredFeed.forEach((feed) => {
            if (feed.post) {
              if (feed.post.id! === id) {
                feed.post.likeCount!--
                feed.post.liked = false
              }
            }
          })
          state.searchFeed = updatedFilteredFeed
        } else if (actionType === "share") {
          const updatedFeed = [...state.feed]
          updatedFeed.forEach((feed) => {
            if (feed.post) {
              if (feed.post.id! === id) {
                feed.post.shareCount!++
                feed.post.shared = true
              }
            }
          })
          state.feed = updatedFeed

          const updatedFilteredFeed = [...state.searchFeed]
          updatedFilteredFeed.forEach((feed) => {
            if (feed.post) {
              if (feed.post.id! === id) {
                feed.post.shareCount!++
                feed.post.shared = true
              }
            }
          })
          state.searchFeed = updatedFilteredFeed
        }
      } else if (type === "news") {
        if (actionType === "like") {
          const updatedFeed = [...state.feed]
          updatedFeed.forEach((feed) => {
            if (feed.news) {
              if (feed.news.id! === id) {
                feed.news.likeCount!++
                feed.news.liked = true
              }
            }
          })
          state.feed = updatedFeed

          const updatedFilteredFeed = [...state.searchFeed]
          updatedFilteredFeed.forEach((feed) => {
            if (feed.news) {
              if (feed.news.id! === id) {
                feed.news.likeCount!++
                feed.news.liked = true
              }
            }
          })
          state.searchFeed = updatedFilteredFeed
        } else if (actionType === "dislike") {
          const updatedFeed = [...state.feed]
          updatedFeed.forEach((feed) => {
            if (feed.news) {
              if (feed.news.id! === id) {
                feed.news.likeCount!--
                feed.news.liked = false
              }
            }
          })
          state.feed = updatedFeed

          const updatedFilteredFeed = [...state.searchFeed]
          updatedFilteredFeed.forEach((feed) => {
            if (feed.news) {
              if (feed.news.id! === id) {
                feed.news.likeCount!--
                feed.news.liked = false
              }
            }
          })
          state.searchFeed = updatedFilteredFeed
        } else if (actionType === "share") {
          const updatedFeed = [...state.feed]
          updatedFeed.forEach((feed) => {
            if (feed.news) {
              if (feed.news.id! === id) {
                feed.news.shareCount!++
                feed.news.shared = true
              }
            }
          })
          state.feed = updatedFeed

          const updatedFilteredFeed = [...state.searchFeed]
          updatedFilteredFeed.forEach((feed) => {
            if (feed.news) {
              if (feed.news.id! === id) {
                feed.news.shareCount!++
                feed.news.shared = true
              }
            }
          })
          state.searchFeed = updatedFilteredFeed
        }

        if (actionType === "comment") {
          const parentCommentId = action.meta.arg.parentCommentId

          const updatedFeed = [...state.feed]
          updatedFeed.forEach((feed) => {
            if (feed.news && feed.news.id! === id && feed.news.comments) {
              if (parentCommentId) {
                const updatedComments = [...feed.news.comments]
                updatedComments.forEach((comment) => {
                  if (comment.id === parentCommentId) {
                    const newReply = action.meta.arg.comment as Comment
                    if (
                      action.meta.arg.mentions &&
                      action.meta.arg.mentions.length > 0
                    ) {
                      newReply.mentions = action.meta.arg.mentions
                    }
                    comment.replies!.unshift(newReply)
                  }
                })
                feed.news.comments = updatedComments
              } else {
                const newComment = action.meta.arg.comment as Comment
                if (
                  action.meta.arg.mentions &&
                  action.meta.arg.mentions.length > 0
                ) {
                  newComment.mentions = action.meta.arg.mentions
                }
                feed.news.comments!.unshift(newComment)
              }
              feed.news.commentCount!++
              feed.news.commented = true
            }
          })
          state.feed = updatedFeed

          const updatedFilteredFeed = [...state.searchFeed]
          updatedFilteredFeed.forEach((feed) => {
            if (feed.news && feed.news.id! === id && feed.news.comments) {
              if (parentCommentId) {
                const updatedComments = [...feed.news.comments]
                updatedComments.forEach((comment) => {
                  if (comment.id === parentCommentId) {
                    const newReply = action.meta.arg.comment as Comment
                    if (
                      action.meta.arg.mentions &&
                      action.meta.arg.mentions.length > 0
                    ) {
                      newReply.mentions = action.meta.arg.mentions
                    }
                    comment.replies!.unshift(newReply)
                  }
                })
                feed.news.comments = updatedComments
              } else {
                const newComment = action.meta.arg.comment as Comment
                if (
                  action.meta.arg.mentions &&
                  action.meta.arg.mentions.length > 0
                ) {
                  newComment.mentions = action.meta.arg.mentions
                }
                feed.news.comments!.unshift(newComment)
              }
              feed.news.commentCount!++
              feed.news.commented = true
            }
          })
          state.searchFeed = updatedFilteredFeed
        }
      }
    })
  },
})
//#endregion

//#region selectors
//#endregion
