import { Auth0ContextInterface } from "@auth0/auth0-react"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useApi } from "../hooks/useApi"
import { News } from "./adminNews"
import { RootState } from "./rootReducer"
import { Comment, CommentMention } from "./projects"

//#region types
export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

type SliceState = {
  status: LoadingStatuses
  error: string | null | undefined
  allNews: News[]
  count: number
  newCount: number
  news?: News
}
//#endregion

//#region api
type LikeNewsPayload = {
  auth: Auth0ContextInterface
  id: string
}
export const likeNews = createAsyncThunk<any, LikeNewsPayload>(
  "news/like",
  async ({ auth, id }) => {
    return useApi(auth, `/news/${id}/like`, {
      method: "POST",
      body: JSON.stringify({ origin: window.location.origin }),
    }).then((res) => res.json())
  }
)

type DislikeNewsPayload = {
  auth: Auth0ContextInterface
  id: string
}
export const dislikeNews = createAsyncThunk<any, DislikeNewsPayload>(
  "news/dislike",
  async ({ auth, id }) => {
    return useApi(auth, `/news/${id}/dislike`, {
      method: "POST",
    }).then((res) => res.json())
  }
)

type ShareNewsPayload = {
  auth: Auth0ContextInterface
  id: string
}
export const shareNews = createAsyncThunk<any, ShareNewsPayload>(
  "news/share",
  async ({ auth, id }) => {
    return useApi(auth, `/news/${id}/share`, {
      method: "POST",
      body: JSON.stringify({ origin: window.location.origin }),
    }).then((res) => res.json())
  }
)

type ViewNewsPayload = {
  auth: Auth0ContextInterface
  id: string
}
export const viewNews = createAsyncThunk<any, ViewNewsPayload>(
  "news/view",
  async ({ auth, id }) => {
    return useApi(auth, `/news/${id}/view`, {
      method: "POST",
      body: JSON.stringify({ origin: window.location.origin }),
    }).then((res) => res.json())
  }
)

type getAllNewsPayload = {
  page?: number
  perPage?: number
  search?: string
  email?: string
  sortBy: string
}
export const getAllNews = createAsyncThunk<any, getAllNewsPayload>(
  "news/fetchAll",
  async ({ email, page = 1, perPage = 8, search, sortBy }) => {
    page = page > 0 ? (page -= 1) : 0
    const skip = page * perPage
    let endpoint = `/news?skip=${skip}&limit=${perPage}`
    if (search) endpoint += `&search=${search}`
    if (email) endpoint += `&email=${email}`
    if (sortBy) endpoint += `&sortBy=${sortBy}`

    return useApi(null, endpoint, {
      method: "GET",
    }).then((res) => res.json())
  }
)

type GetNewsPayload = {
  id: string
  email?: string
}
export const getNews = createAsyncThunk<any, GetNewsPayload>(
  "news/fetch",
  async ({ id, email }) => {
    let endpoint = `/news/${id}`
    if (email) endpoint += `?email=${email}`

    return useApi(null, endpoint, {
      method: "GET",
    }).then((res) => res.json())
  }
)

type UpdateCommentStatePayload = {
  id: string
  comment?: Comment
  mentions?: CommentMention[]
  parentCommentId?: string
}

export const updateCommentsState = createAsyncThunk<
  any,
  UpdateCommentStatePayload
>(
  "news/updateCommentsState",
  async ({ id, comment, mentions, parentCommentId }) => {
    return ""
  }
)

type UpdateEditedCommentStatePayload = {
  comment?: Comment
  mentions?: CommentMention[]
}

export const updateEditedCommentsState = createAsyncThunk<
  any,
  UpdateEditedCommentStatePayload
>("news/updateEditedCommentsState", async ({ comment, mentions }) => {
  return ""
})

//#endregion

//#region slice
const initialState: SliceState = {
  status: LoadingStatuses.Idle,
  error: undefined,
  allNews: [],
  count: 0,
  newCount: 0,
  news: undefined,
}

export default createSlice({
  name: "news",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllNews.fulfilled, (state, action) => {
        state.count = action.payload.count
        state.status = LoadingStatuses.Succeeded

        state.newCount = state.newCount + action.payload.data.length

        state.allNews =
          action.meta.arg.page === 0 || action.meta.arg.page === 1
            ? action.payload.data
            : state.allNews?.concat(action.payload.data)

        state.error = null
      })
      .addCase(getAllNews.pending, (state, action) => {
        state.status = LoadingStatuses.Loading

        if (action.meta.arg.page === 0 || action.meta.arg.page === 1) {
          state.allNews = []
          state.newCount = 0
        }
      })
      .addCase(getNews.fulfilled, (state, action) => {
        state.news = action.payload
      })
      .addCase(getNews.pending, (state) => {
        state.news = undefined
      })
      .addCase(updateCommentsState.fulfilled, (state, action) => {
        if (state.news) {
          if (action.meta.arg.parentCommentId && state.news.comments) {
            const updatedCommentsReplies = [...state.news.comments]
            updatedCommentsReplies.forEach((comment) => {
              if (comment.id === action.meta.arg.parentCommentId) {
                const newReply = action.meta.arg.comment
                if (newReply) {
                  if (action.meta.arg.mentions) {
                    newReply.mentions = action.meta.arg.mentions
                  }
                  comment.replies!.unshift(newReply)
                }
              }
            })

            state.news.commentCount!++
            state.news.commented = true
          } else {
            const news = {
              ...state.news,
            } as News

            const newComment = action.meta.arg.comment
            if (newComment) {
              if (action.meta.arg.mentions) {
                newComment.mentions = action.meta.arg.mentions
              }
              news.comments!.unshift(newComment)
            }
            state.news = news
            state.news.commentCount!++
            state.news.commented = true
          }
        }

        if (state.allNews) {
          const updatedAllNews = [...state.allNews]
          updatedAllNews.forEach((news) => {
            if (news.id! === action.meta.arg.id) {
              if (action.meta.arg.parentCommentId && news.comments) {
                const updatedCommentsRepliesAllNews = [...news.comments]
                updatedCommentsRepliesAllNews.forEach((comment) => {
                  if (comment.id === action.meta.arg.parentCommentId) {
                    const newReply = action.meta.arg.comment
                    if (newReply) {
                      if (action.meta.arg.mentions) {
                        newReply.mentions = action.meta.arg.mentions
                      }
                      comment.replies!.unshift(newReply)
                    }
                  }
                })
                news.comments = updatedCommentsRepliesAllNews
              } else {
                const newComment = action.meta.arg.comment
                if (newComment) {
                  if (action.meta.arg.mentions) {
                    newComment.mentions = action.meta.arg.mentions
                  }
                  news.comments!.unshift(newComment)
                }
              }

              news.commentCount!++
              news.commented = true
            }
          })

          state.allNews = updatedAllNews
        }
      })
      .addCase(updateEditedCommentsState.fulfilled, (state, action) => {
        if (state.news) {
          const news = { ...state.news }
          const updatedComments = [...state.news!.comments!]
          updatedComments.map((comment) => {
            if (comment.id! === action.meta.arg.comment?.id) {
              comment.text = action.meta.arg.comment.text

              if (action.meta.arg.comment.hidden !== comment.hidden) {
                comment.hidden = action.meta.arg.comment.hidden
              }

              if (action.meta.arg.mentions) {
                comment.mentions = action.meta.arg.mentions
              }
            }
            return comment
          })

          const commentsWithoutHidden = updatedComments.filter((comment) => {
            if (!comment.hidden && !comment.author?.isAdmin) return comment

            return false
          })

          news.comments = commentsWithoutHidden
          news.commentCount = commentsWithoutHidden.length
          state.news = news
        }
      })
  },
})
//#endregion

//#region selectors
export const selectNews = ({ news }: RootState) => news.news
//#endregion
