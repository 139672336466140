import { Auth0ContextInterface } from "@auth0/auth0-react"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useApi } from "../hooks/useApi"
import { RootState } from "./rootReducer"
import { ProjectFeatured } from "./adminProjectFeatured"
import { ProjectMainFeature } from "./adminProjectMainFeature"
import { ProjectReward } from "./adminProjectReward"
import { ProjectFlag, Project as RegularProject } from "./projects"
//#region types
export type Image = {
  id?: string
  order: number
  thumbnailImageUrl?: string
  largeImageUrl?: string
  url?: string
  file?: any
  dimensions?: { width: number; height: number }
}

export type Project = {
  id?: string
  profileId?: string
  name: string
  description: string
  location?: string
  category: string
  state: string
  createdAt?: string
  commentsCount?: number
  likes?: number
  features?: ProjectFeatured[]
  featured?: boolean
  mainFeature?: ProjectMainFeature[]
  mainFeatured?: boolean
  images?: Array<Image>
  removedImages?: Array<string>
  liked?: boolean
  role?: string
  canBeLiked?: boolean
  nominations?: number
  nominated?: boolean
  canBeNominated?: boolean
  comments?: Comment[]
  featuredAt?: string
  tags?: string[]
  rewards?: ProjectReward[]
  flags?: ProjectFlag[]
}

export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

export type SortDirection = "asc" | "desc"
export type SortProperty =
  | "name"
  | "likes"
  | "comments"
  | "created_at"
  | "location"
export type SortType = {
  property: SortProperty | undefined
  direction: SortDirection
}

type SliceState = {
  projects: Project[]
  count: number
  flaggedProjects: RegularProject[]
  project: Project | null | undefined
  flaggedProject: RegularProject | null | undefined
  status: LoadingStatuses
  error: string | null | undefined
}
//#endregion

//#region api
type FetchProjectsPayload = {
  auth: Auth0ContextInterface
  sort: SortType
  search?: string
  page?: number
  perPage?: number
}
export const fetchProjects = createAsyncThunk<any, FetchProjectsPayload>(
  "projects/admin/fetchProjects",
  async ({ auth, sort, search, page = 1, perPage = 25 }) => {
    page = page > 0 ? (page -= 1) : 0
    const skip = page * perPage
    let endpoint = `/admin/projects?skip=${skip}&limit=${perPage}`
    if (search) {
      endpoint += `&search=${search}`
    }
    if (sort.property)
      endpoint += `&order=${
        sort.property
      }&direction=${sort.direction.toUpperCase()}`
    return useApi(auth, endpoint).then((res) => res.json())
  }
)

type FetchProjectPayload = {
  id: string
  auth: Auth0ContextInterface
}
export const fetchProject = createAsyncThunk<any, FetchProjectPayload>(
  "projects/admin/fetchProject",
  async ({ auth, id }) => {
    return useApi(auth, `/admin/projects/${id}`).then((res) => res.json())
  }
)

type UpdateProjectPayload = {
  auth: Auth0ContextInterface
  project: Project
}
export const updateProject = createAsyncThunk<any, UpdateProjectPayload>(
  "projects/admin/updateProject",
  async ({ auth, project }) => {
    const form = new FormData()
    form.append("name", project.name)
    form.append("description", project.description)
    form.append("category", project.category)
    form.append("state", project.state)
    project.role && form.append("role", project.role)
    project.location && form.append("location", project.location)
    form.append("tags", JSON.stringify(project.tags as string[]))

    const projectImages = project.images ?? []
    const existingImages = projectImages.filter((image) => !image.file)
    const newImages = projectImages.filter((image) => image.file)

    existingImages.map((image) =>
      form.append(
        "images[]",
        JSON.stringify({ id: image.id, order: image.order })
      )
    )
    newImages.forEach((image) =>
      form.append(`image-${image.order}`, image.file)
    )
    project.removedImages?.forEach((id) => form.append("removedImages[]", id))

    return useApi(auth, `/admin/projects/${project.id}`, {
      method: "PATCH",
      body: form,
      headers: {
        "Content-Type": "Automatic",
      },
    }).then((res) => res.json())
  }
)

type FetchFlaggedProjects = {
  auth: Auth0ContextInterface
}
export const fetchFlaggedProjects = createAsyncThunk<any, FetchFlaggedProjects>(
  "posts/admin/flaggedProjects",
  async ({ auth }) => {
    const endpoint = "/admin/flaggedProjects"
    return useApi(auth, endpoint).then((res) => res.json())
  }
)

type FetchFlaggedProjectPayload = {
  id: string
  auth: Auth0ContextInterface
}
export const fetchFlaggedProject = createAsyncThunk<
  any,
  FetchFlaggedProjectPayload
>("posts/admin/fetchFlaggedProject", async ({ auth, id }) => {
  return useApi(auth, `/admin/flaggedProjects/${id}`).then((res) => res.json())
})

type UpdateFlaggedProject = {
  auth: Auth0ContextInterface
  status: string
  id: string
}
export const updateFlaggedProject = createAsyncThunk<any, UpdateFlaggedProject>(
  "posts/admin/updateFlaggedProject",
  async ({ auth, status, id }) => {
    return useApi(auth, `/admin/projects/flag/${id}`, {
      method: "PATCH",
      body: JSON.stringify({ status }),
    }).then((res) => res.text())
  }
)
//#endregion

//#region slice
const initialState: SliceState = {
  projects: [],
  count: 0,
  flaggedProjects: [],
  project: undefined,
  flaggedProject: undefined,
  status: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: "adminProjects",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjects.fulfilled, (state, action) => {
        state.status = LoadingStatuses.Succeeded
        state.projects = action.payload.data
        state.count = action.payload.count
        state.error = null
      })
      .addCase(fetchProjects.rejected, (state, action) => {
        state.status = LoadingStatuses.Failed
        state.error = action.error.message
      })
      .addCase(fetchProject.pending, (state, _action) => {
        state.status = LoadingStatuses.Loading
        state.project = null
        state.error = null
      })
      .addCase(fetchProject.fulfilled, (state, action) => {
        state.status = LoadingStatuses.Succeeded
        state.project = action.payload
      })
      .addCase(fetchProject.rejected, (state, action) => {
        state.status = LoadingStatuses.Failed
        state.project = null
        state.error = action.error.message
      })
      .addCase(fetchFlaggedProject.pending, (state, _action) => {
        state.status = LoadingStatuses.Loading
        state.flaggedProject = null
        state.error = null
      })
      .addCase(fetchFlaggedProject.fulfilled, (state, action) => {
        state.status = LoadingStatuses.Succeeded
        state.flaggedProject = action.payload
      })
      .addCase(updateProject.fulfilled, (state, action) => {
        state.error = null
      })
      .addCase(updateProject.rejected, (state, action) => {
        state.status = LoadingStatuses.Failed
        state.error = action.error.message
      })
      .addCase(fetchFlaggedProjects.fulfilled, (state, action) => {
        state.status = LoadingStatuses.Succeeded
        state.flaggedProjects = action.payload
        state.error = null
      })
      .addCase(updateFlaggedProject.fulfilled, (state, action) => {
        const updatedProjects = [...state.flaggedProjects]
        updatedProjects.forEach((post, key) => {
          if (post.id! === action.meta.arg.id) {
            updatedProjects.splice(key, 1)
          }
        })
        state.flaggedProjects = updatedProjects
      })
  },
})
//#endregion

//#region selectors
export const selectAllProjects = ({ adminProjects }: RootState) =>
  adminProjects.projects
export const selectAllProjectsCount = ({ adminProjects }: RootState) =>
  adminProjects.count
export const selectAllFlaggedProjects = ({ adminProjects }: RootState) =>
  adminProjects.flaggedProjects
export const selectProject = ({ adminProjects }: RootState) =>
  adminProjects.project
export const selectFlaggedProject = ({ adminProjects }: RootState) =>
  adminProjects.flaggedProject
//#endregion
