import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "../../../redux/configureStore"
import {
  fetchProjects,
  selectAllProjects,
  SortType,
  SortProperty,
  selectAllProjectsCount,
} from "../../../redux/adminProjects"
import View from "./View"
import { useAuth0 } from "@auth0/auth0-react"

const AdminProjectsPage = () => {
  const auth = useAuth0()
  const dispatch = useAppDispatch()
  const projects = useSelector(selectAllProjects) || []
  const projectsCount = useSelector(selectAllProjectsCount) || 0
  const [sort, setSort] = useState<SortType>({
    property: undefined,
    direction: "asc",
  })

  const [search, setSearch] = useState("")
  const [page, setPage] = useState(1)

  const handleSortChanged = (property: SortProperty) => {
    const isAsc = sort.property === property && sort.direction === "asc"
    setSort({ property, direction: isAsc ? "desc" : "asc" })
  }

  useEffect(() => {
    ;(async () => {
      try {
        await dispatch(fetchProjects({ auth, sort, search, page }))
      } catch (error) {
        console.error(error)
      }
    })()
  }, [dispatch, auth, sort, search, page])

  const handleChangeSearch = (value: string) => {
    setPage(1)
    setSearch(value)
  }

  return (
    <View
      projects={projects}
      onSortChanged={handleSortChanged}
      sort={sort}
      projectsCount={projectsCount}
      page={projectsCount}
      onChangeSearch={handleChangeSearch}
      onChangePage={setPage}
    />
  )
}

export default AdminProjectsPage
