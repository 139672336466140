import React from "react"
import { PostsBox, PostsContainer } from "./styles"
import BackButton from "../../components/BackButton"
import environment from "../../environment"
import { News } from "../../redux/adminNews"
import NewsCard from "../../cards/NewsCard"

export type Props = {
  news: News
}

const View = ({ news }: Props) => {
  return (
    <>
      <BackButton style={{ marginLeft: "20px" }} />
      <PostsContainer
        style={{ marginTop: environment.device === "ios-app" ? "35px" : "" }}
      >
        <PostsBox>
          <NewsCard news={news} />
        </PostsBox>
      </PostsContainer>
    </>
  )
}

export default View
