import React, { MouseEventHandler, useEffect } from "react"
import { NavLink as RouterLink } from "react-router-dom"
import { Link, useLocation } from "react-router-dom"
import {
  Hidden,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
  Typography,
} from "@material-ui/core"
import {
  NavbarContainer,
  Logo,
  LogoContainer,
  Actions,
  NavBarButton,
  Drawer,
  NavBarCreateAccountButton,
  StyledLink,
} from "./styles"
import theme from "../../theme"
import { useWindowDimensions } from "../../hooks"
import LogoImage from "../../images/logo-charcoal.svg"
import { CommonNavbar } from "../Navbar/common-navbar"
import { useAuth0 } from "@auth0/auth0-react"
import { MenuContainer } from "../Navbar/styles"
import { Browser } from "@capacitor/browser"
import environment from "../../environment"
import { AwardsNavItem } from "../AwardsNavItem"
import LiveIcon from "../../images/new-header/live-icon.svg"
import { useSelector } from "react-redux"
import { selectCompetitions } from "../../redux/competition"
import { ServicesNavItem } from "../ServicesNavItem"

export type Props = {
  hideSignUp?: boolean
  menuOpen: boolean
  goToUrl: Function
  onMenuClose: MouseEventHandler
  isDrawerOpen: boolean
  onDrawerOpen: () => void
  onDrawerClose: () => void
}

const View = ({
  hideSignUp,
  menuOpen,
  goToUrl,
  onMenuClose,
  isDrawerOpen,
  onDrawerOpen,
  onDrawerClose,
}: Props) => {
  const { loginWithRedirect } = useAuth0()
  const { pathname } = useLocation()
  const { width } = useWindowDimensions()
  const isMobile = width <= theme.breakpoints.values.md

  const login = async () => {
    if (environment.device === "desktop") {
      await loginWithRedirect()
    } else {
      await loginWithRedirect({
        async openUrl(url: string) {
          await Browser.open({
            url,
            windowName: "_self",
          })
        },
      })
    }
  }

  useEffect(() => {
    if (!isMobile) {
      if (!document.getElementById("common-navigation-main")) {
        CommonNavbar()
      }
    }
  }, [isMobile])

  const competitions = useSelector(selectCompetitions)
  const liveCompetition = competitions?.find(
    (competition) => competition.status === "open"
  )

  return (
    <NavbarContainer
      maxWidth="xl"
      aria-label="header navigation bar"
      style={pathname.includes("/sp") ? { marginBottom: "40px" } : {}}
      className="navbar-container"
    >
      <LogoContainer>
        <Link to="/">
          <>
            <Hidden smDown>
              <Logo
                src={LogoImage}
                alt="Spectacular"
                aria-label="spectacular logo"
              />
            </Hidden>
            <Hidden mdUp>
              <Logo
                src={LogoImage}
                alt="Spectacular"
                aria-label="spectacular logo"
              />
            </Hidden>
          </>
        </Link>
      </LogoContainer>
      <MenuContainer>
        <Hidden smDown implementation="css">
          <Box display="flex" flexDirection="row">
            <AwardsNavItem />
            {liveCompetition && (
              <StyledLink
                component={RouterLink}
                to={liveCompetition.url}
                aria-label="studio-awards"
                activeClassName="link-active"
                exact={true}
              >
                {liveCompetition.displayTitle} <img src={LiveIcon} alt="Live" />
              </StyledLink>
            )}
            <ServicesNavItem />
            <StyledLink
              component={RouterLink}
              to="/about"
              aria-label="about"
              activeClassName="link-active"
              exact={true}
            >
              About
            </StyledLink>
            <StyledLink
              component={RouterLink}
              to="/jobs"
              aria-label="jobs"
              activeClassName="link-active"
              exact={true}
            >
              Jobs
            </StyledLink>
            <StyledLink
              component={RouterLink}
              to="/student-ambassador"
              aria-label="student-ambassador"
              activeClassName="link-active"
              exact={true}
            >
              Student Ambassador <img src={LiveIcon} alt="Live" />
            </StyledLink>
          </Box>
        </Hidden>
      </MenuContainer>
      <Box
        style={{
          width: width >= theme.breakpoints.values.sm ? "150px" : "100px",
          textAlign: "right",
        }}
      >
        {!hideSignUp && (
          <NavBarCreateAccountButton
            data-testid="goToRegister"
            onClick={() => goToUrl("/register")}
          >
            Create an account
          </NavBarCreateAccountButton>
        )}
      </Box>
      <Hidden smDown implementation="css">
        <Actions>
          <NavBarButton
            aria-label="Login"
            data-testid="login-button"
            onClick={login}
            style={{ background: "#FFFFFF", borderLeft: "1px solid #2d2d2d" }}
          >
            Login
          </NavBarButton>
          <NavBarButton
            aria-label="Sign up"
            color="primary"
            data-testid="sign-up-button"
            onClick={() => goToUrl("/register")}
          >
            Sign up
          </NavBarButton>
        </Actions>
      </Hidden>
      <Hidden mdUp implementation="css">
        <NavBarButton color="primary" onClick={onDrawerOpen}>
          Menu
        </NavBarButton>
      </Hidden>
      <Drawer anchor="right" open={isDrawerOpen} onClose={onDrawerClose}>
        <List style={{ width: "180px" }}>
          <ListItem button onClick={() => goToUrl("/")}>
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem button onClick={() => goToUrl("/about")}>
            <ListItemText primary="About" />
          </ListItem>
          <ListItem button onClick={() => goToUrl("/jobs")}>
            <ListItemText primary="Jobs" />
          </ListItem>
          <ListItem button onClick={() => goToUrl("/student-ambassador")}>
            <ListItemText
              primary={
                <>
                  Student Ambassador <img src={LiveIcon} alt="Live" />
                </>
              }
            />
          </ListItem>
          {liveCompetition && (
            <ListItem button onClick={() => goToUrl(liveCompetition.url)}>
              <ListItemText
                primary={
                  <>
                    {liveCompetition.displayTitle}{" "}
                    <img src={LiveIcon} alt="Live" />
                  </>
                }
              />
            </ListItem>
          )}
          <ListItem button onClick={() => goToUrl("/firm-spotlight")}>
            <ListItemText primary="Firm Spotlight" />
          </ListItem>

          {environment.device === "desktop" ? (
            <ListItem
              button
              onClick={() =>
                window.open("https://www.spectacular.design/advertising")
              }
            >
              <ListItemText primary="Advertising" />
            </ListItem>
          ) : (
            <ListItem
              button
              onClick={async () => {
                await Browser.open({
                  url: "https://www.spectacular.design/advertising",
                  windowName: "_self",
                })
              }}
            >
              <ListItemText primary="Advertising" />
            </ListItem>
          )}

          <Divider />

          <ListItem button>
            <ListItemText onClick={login} primary="Login" />
          </ListItem>
          <ListItem
            button
            onClick={() => goToUrl("/register")}
            style={{ background: "#2d2d2d" }}
          >
            <ListItemText
              disableTypography
              primary={
                <Typography
                  variant="body2"
                  style={{ color: "#FFFFFF", fontSize: "16px" }}
                >
                  Sign up
                </Typography>
              }
            />
          </ListItem>
        </List>
      </Drawer>
    </NavbarContainer>
  )
}

export default View
