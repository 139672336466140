import React from "react"
import { Profile } from "../../../redux/profile"
import {
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Box,
  Container,
  Grid,
  Button,
  TextField,
} from "@material-ui/core"
import { Link } from "react-router-dom"
import { ChipStyled } from "./styles"
import { FormBox } from "../NewsPage/Styles"
import { Pagination } from "@material-ui/lab"

export type Props = {
  profiles: Profile[]
  onChangeSearch: (value: string) => void
  profilesCount: number
  onChangePage: (value: number) => void
  page: number
}

const View = ({
  profiles,
  onChangeSearch,
  profilesCount,
  onChangePage,
  page,
}: Props) => {
  const totalPages = Math.ceil(profilesCount / 25)

  const handlePagination = (_: any, page: number) => {
    onChangePage(page)
    document
      .getElementById("ProfilesList")
      ?.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <Container>
      <Box marginBottom={2} id="ProfilesList">
        <Typography variant="h2">Profiles List</Typography>
      </Box>

      <FormBox component={Paper}>
        <TextField
          label="Search"
          onChange={(e) => onChangeSearch(e.target.value)}
        />
      </FormBox>

      <Box marginBottom={1}>
        <Grid container>
          <Grid item xs container justify="flex-end">
            <Button
              component={Link}
              to="/admin/profiles/new"
              color="primary"
              size="small"
            >
              Add new profile
            </Button>
          </Grid>
        </Grid>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Name/Group name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Archived</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {profiles.map((profile) => {
              let accountType: any = profile.type
              if (profile.role === "admin") accountType = profile.role
              let name = profile.groupName
              if (profile.type === "individual") {
                if (profile.firstName) {
                  name = `${profile.firstName} ${profile.lastName}`
                } else {
                  name = `${profile.lastName}`
                }
              }
              return (
                <TableRow key={profile.username}>
                  <TableCell component="th" scope="row">
                    <ChipStyled
                      size="small"
                      label={accountType}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell>{name}</TableCell>
                  <TableCell>{profile.email}</TableCell>
                  <TableCell>{profile.username}</TableCell>
                  <TableCell>{profile.archivedAt ? "Yes" : "No"}</TableCell>
                  <TableCell>
                    <Box paddingRight={1} display="inline">
                      {!profile.archivedAt && (
                        <Link to={`/admin/profiles/${profile.username!}/edit`}>
                          Edit
                        </Link>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box paddingTop={5} paddingBottom={8}>
        {totalPages > 1 && (
          <Pagination
            variant="outlined"
            page={page}
            onChange={handlePagination}
            count={totalPages}
            data-testid="load-more-profiles-pagination"
          />
        )}
      </Box>
    </Container>
  )
}

export default View
