import React, { useCallback, useEffect } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "../../redux/configureStore"

import LoadingPage from "../LoadingPage"
import { useAuth0 } from "@auth0/auth0-react"
import { Container } from "@material-ui/core"
import { getProducts, selectProducts } from "../../redux/product"
import { logEvent } from "../../utils/analytics"
import Plan from "../PromotionWizardPage/Plan"
import { selectProfile } from "../../redux/profile"

const PricingPage = () => {
  const auth = useAuth0()
  const dispatch = useAppDispatch()

  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  const products = useSelector(selectProducts)
  const profile = useSelector(selectProfile)

  const fetchProducts = useCallback(async () => {
    await dispatch(getProducts({ type: "job", email: profile?.email }))
    await logEvent("pricing_page_view")
  }, [dispatch, profile])

  useEffect(() => {
    fetchProducts()
  }, [fetchProducts])

  return products ? (
    <>
      <Container style={!auth.isAuthenticated ? { marginTop: "40px" } : {}}>
        <Plan products={products} />
      </Container>
    </>
  ) : (
    <LoadingPage />
  )
}

export default PricingPage
