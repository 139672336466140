import React from "react"

import { NewsCardActionsBox } from "../../components/Styles/Global"
import { News } from "../../redux/adminNews"
import NewsLikeIcon from "../NewsLikeIcon"
import NewsShareIcon from "../NewsShareIcon"

import IconCommented from "../../images/new-homepage/icons/comment-active.svg"
import IconNoComment from "../../images/new-homepage/icons/comment-default.svg"
import IconHover from "../../images/new-homepage/icons/comment-hover.svg"
import {
  CounterFlexBox,
  CountLabel,
  Icon,
  IconContainer,
  IconFlexBox,
} from "../ProjectCardIconActions/styles"
import { Typography } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"
import { useAppDispatch } from "../../redux/configureStore"
import { toggleDialog } from "../../redux/config"

type Props = {
  news: News
}

const View = ({ news }: Props) => {
  const [hover, setHover] = React.useState(false)

  const auth = useAuth0()
  const history = useHistory()
  const dispatch = useAppDispatch()

  const getCommentIcon = (commented?: boolean) => {
    if (commented) return IconCommented

    if (hover) return IconHover

    return IconNoComment
  }

  const goToNews = async (id: string) => {
    if (!auth.isAuthenticated) {
      await dispatch(toggleDialog({ opened: true }))
      return
    }

    history.push(`/news/${news.id}`)
  }

  return (
    <NewsCardActionsBox>
      <NewsLikeIcon news={news} />
      <IconContainer
        $isActive={news.commented || false}
        onClick={() => goToNews(news.id)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <IconFlexBox>
          <CounterFlexBox>
            <Icon src={getCommentIcon(news.commented)} alt="Comment" />
            <CountLabel>{news.commentCount}</CountLabel>
          </CounterFlexBox>
          <Typography>Comment</Typography>
        </IconFlexBox>
      </IconContainer>
      <NewsShareIcon news={news} />
    </NewsCardActionsBox>
  )
}

export default View
