import React, { useState } from "react"
import View from "./View"
import { News } from "../../redux/adminNews"
import { useAppDispatch } from "../../redux/configureStore"
import { updateCommentsState, viewNews } from "../../redux/news"
import { useAuth0 } from "@auth0/auth0-react"
import { toggleDialog } from "../../redux/config"
import { addComment, CommentMention } from "../../redux/projects"
import { updateFeedState } from "../../redux/feed"

export type Props = {
  news: News
}

const NewsCard = ({ news }: Props) => {
  const [isPostingComment, setIsPostingComment] = useState(false)

  const auth = useAuth0()
  const dispatch = useAppDispatch()

  const handleNewsVisit = async () => {
    try {
      await dispatch(viewNews({ auth, id: news.id! }))
    } catch (e) {
      console.error(e)
    }
  }

  const handlePostComment = async (
    text: string,
    parentCommentId?: string,
    mentions?: CommentMention[]
  ) => {
    if (!auth.isAuthenticated) {
      await dispatch(toggleDialog({ opened: true }))
      return
    }

    try {
      setIsPostingComment(true)
      const { type, payload } = await dispatch(
        addComment({
          id: news.id!,
          auth,
          text,
          parentCommentId,
          mentions,
          source: "news",
        })
      )
      if (addComment.fulfilled.type === type) {
        setIsPostingComment(false)

        await dispatch(
          updateFeedState({
            type: "news",
            id: news.id!,
            action: "comment",
            comment: payload,
            parentCommentId,
            mentions,
          })
        )
        await dispatch(
          updateCommentsState({
            id: news.id!,
            comment: payload,
            parentCommentId,
            mentions,
          })
        )
      }
    } catch (error) {
      console.error(error)
      setIsPostingComment(false)
    }
  }

  return (
    <View
      news={news}
      onNewsVisit={handleNewsVisit}
      isPostingComment={isPostingComment}
      handlePostComment={handlePostComment}
    />
  )
}

export default NewsCard
