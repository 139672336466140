import React from "react"
import {
  Divider,
  CardBox,
  CardActions,
  PostCardContentImage,
  CardHeader,
  ProfileTitle,
  ImageThumbnail,
  CardFooter,
  DividerBorder,
  SeeMoreButton,
  CardBigTextBelow,
  LinkBox,
  HeaderBox,
  StyledAvatar,
  CardFooterTitle,
  CardFooterHint,
  HeaderDate,
  CardContentBigText,
  CardBigText,
  CardTitle,
  CardHint,
} from "./styles"
import Zoomable from "react-instagram-zoom"
import { News } from "../../redux/adminNews"
import NewsCardIconActions from "../../components/NewsCardIconActions"
import moment from "moment"
import {
  BlockingBox,
  CommentsBox,
  CommentsInnerBox,
  CommentsLabel,
} from "../ProjectCard/styles"
import CommentTextField from "../../components/CommentTextField"
import CommentsList from "../ProjectCard/CommentsList"
import { CommentMention } from "../../redux/projects"
import { useWindowDimensions } from "../../hooks"
import theme from "../../theme"
import { useSelector } from "react-redux"
import { selectProfile } from "../../redux/profile"

export type Props = {
  news: News
  onNewsVisit: () => void
  isPostingComment: boolean
  handlePostComment: (
    text: string,
    parentCommentId?: string,
    mentions?: CommentMention[]
  ) => void
}

const View = ({
  news,
  onNewsVisit,
  isPostingComment,
  handlePostComment,
}: Props) => {
  const [commentsQuantity, setCommentsQuantity] = React.useState(1)

  const { width } = useWindowDimensions()

  const isMobile = width < theme.breakpoints.values.sm

  const authenticatedProfile = useSelector(selectProfile)

  const handleSowMoreComments = () => {
    if (news.comments && news.comments?.length > commentsQuantity)
      setCommentsQuantity(commentsQuantity + 3)
    else setCommentsQuantity(1)
  }

  const getCommentLabel = () => {
    if (news.comments?.length === 0) return <>Be the first to comment</>

    if (
      news.comments &&
      news.comments?.length > 1 &&
      news.comments?.length <= commentsQuantity
    )
      return <>View less</>

    if (news.comments?.length === 1) return <>&nbsp;</>

    return <>View more comments</>
  }

  const postComment = (comment: string, mentions?: CommentMention[]) => {
    if (!isPostingComment && comment) {
      handlePostComment(comment, undefined, mentions)
    }
  }

  const renderContentComponent = () => {
    if (news.imageUrl) {
      return (
        <PostCardContentImage>
          <Zoomable>
            <LinkBox
              href={news.url}
              target="_blank"
              referrerPolicy="noreferrer"
              onClick={onNewsVisit}
            >
              <ImageThumbnail src={news.imageUrl} alt={news.title} />
            </LinkBox>
          </Zoomable>
        </PostCardContentImage>
      )
    } else {
      return (
        <CardContentBigText>
          <CardTitle>{news.title}</CardTitle>
          <CardHint>from {news.origin.name}</CardHint>
          <CardBigText>{news.description}</CardBigText>
          <LinkBox
            href={news.url}
            target="_blank"
            referrerPolicy="noreferrer"
            onClick={onNewsVisit}
          >
            <SeeMoreButton>Read more</SeeMoreButton>
          </LinkBox>
        </CardContentBigText>
      )
    }
  }

  return (
    <CardBox key={news.id}>
      <CardHeader>
        <HeaderBox>
          <LinkBox
            href={news.url}
            target="_blank"
            referrerPolicy="noreferrer"
            onClick={onNewsVisit}
          >
            <StyledAvatar src={news.origin.logo} />
          </LinkBox>
          <LinkBox
            href={news.url}
            target="_blank"
            referrerPolicy="noreferrer"
            onClick={onNewsVisit}
          >
            <ProfileTitle>{news.origin.name}</ProfileTitle>
            {news.date && (
              <HeaderDate>{moment(news.date).format("MMM DD")}</HeaderDate>
            )}
          </LinkBox>
        </HeaderBox>
      </CardHeader>

      {renderContentComponent()}

      <CardActions>
        <NewsCardIconActions news={news} />
      </CardActions>
      <Divider>
        <DividerBorder />
      </Divider>

      {news.imageUrl && (
        <CardFooter>
          <CardFooterTitle>{news.title}</CardFooterTitle>
          <CardFooterHint>from {news.origin.name}</CardFooterHint>
          <CardBigTextBelow>{news.description}</CardBigTextBelow>
          <LinkBox
            href={news.url}
            target="_blank"
            referrerPolicy="noreferrer"
            onClick={onNewsVisit}
          >
            <SeeMoreButton>Read More</SeeMoreButton>
          </LinkBox>
        </CardFooter>
      )}

      <>
        <BlockingBox $isPosting={isPostingComment}>
          <CommentsBox>
            <DividerBorder />
            <CommentsInnerBox>
              {(!isMobile || (news.comments && news.comments?.length > 1)) && (
                <CommentsLabel onClick={handleSowMoreComments}>
                  {getCommentLabel()}
                </CommentsLabel>
              )}
              <CommentTextField
                handleSave={postComment}
                oneLine={true}
                focus={false}
              />
              {news.comments && news.comments?.length > 0 && (
                <CommentsList
                  comments={news.comments}
                  quantity={commentsQuantity!}
                  handlePostComment={handlePostComment}
                  isPostingComment={isPostingComment}
                  authenticatedProfile={authenticatedProfile}
                  isHomepage={true}
                  source="news"
                />
              )}
            </CommentsInnerBox>
          </CommentsBox>
        </BlockingBox>
      </>
    </CardBox>
  )
}

export default View
