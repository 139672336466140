import React, { MouseEvent, useState } from "react"
import { ItemBox, MenuBox, StyledA } from "./styles"
import MenuArrow from "../../images/new-header/menu-drop-down.svg"
import MenuArrowActive from "../../images/new-header/menu-drop-down-active.svg"
import MenuArrowHover from "../../images/new-header/menu-drop-down-hover.svg"
import { Popover } from "@material-ui/core"

export const ServicesNavItem = () => {
  const [isHover, setIsHover] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = async (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const open = Boolean(anchorEl)

  const MenuIcon = open ? MenuArrowActive : isHover ? MenuArrowHover : MenuArrow

  return (
    <>
      <ItemBox
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={handleClick}
      >
        Services
        <img src={MenuIcon} alt="Arrow" />
      </ItemBox>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            boxShadow: "2px 2px 2px 0px rgba(0, 0, 0, 0.1)",
            marginTop: "30px",
          },
        }}
      >
        <MenuBox>
          <StyledA
            onClick={handleClose}
            href={"https://www.spectacular.design/advertising"}
            target="_blank"
          >
            Advertising
          </StyledA>
        </MenuBox>
      </Popover>
    </>
  )
}
