import React, { useCallback, useEffect } from "react"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { useAppDispatch } from "../../redux/configureStore"
import View from "./view"
import { Helmet } from "react-helmet"
import { useAuth0 } from "@auth0/auth0-react"
import LoadingPage from "../LoadingPage"
import RouteChangeTracker from "../../components/RouteChangeTracker"
import { getNews, selectNews } from "../../redux/news"
import camelize from "../../utils/camelize"

interface Params {
  id: string
}

const NewsPage = () => {
  const { id } = useParams<Params>()
  const { user } = useAuth0()
  const email = user?.email
  const dispatch = useAppDispatch()

  const news = useSelector(selectNews)

  const loadNews = useCallback(async () => {
    try {
      await dispatch(
        getNews({
          id,
          email,
        })
      )
    } catch (e) {
      // TODO: implement UI error message
      console.error(e)
    }
  }, [dispatch, email, id])

  useEffect(() => {
    loadNews()
  }, [loadNews])

  if (!news) {
    return <LoadingPage />
  } else {
    const screenTitle = `News Details - ${news.title}`
    return (
      <>
        <RouteChangeTracker
          screenTitle={screenTitle}
          classTitle={camelize(screenTitle)}
        />
        <Helmet>
          <title>{news.title} | Spectacular</title>
        </Helmet>
        <View news={news} />
      </>
    )
  }
}

export default NewsPage
